import { ValidationProvider } from 'vee-validate'
import { computed, getCurrentInstance, ref, watch, onMounted } from 'vue'

export type InputValue = string | number
type ValidationProp = {
  mode?: string
  rules?: string
  name?: string
} | null

export type InputProps = {
  name?: string
  value?: InputValue
  disabled?: boolean
  validation: ValidationProp
}

type InputEmits = {
  (event: 'focus'): void
  (event: 'blur'): void
  (event: 'input', value: InputValue): void
}

export const baseInputProps = {
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  icon: {
    type: String,
    default: '',
  },
  iconSize: {
    type: [String, Number],
    default: 'md',
  },
  value: {
    type: [String, Number],
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  error: {
    type: [String, Array],
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  requiredAsterisk: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  nolabel: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: '',
  },
  autofocus: {
    type: Boolean,
    default: false,
  },
  pattern: {
    type: String,
    default: null,
  },
  floated: {
    type: Boolean,
    default: false,
  },
  prefix: {
    type: String,
    default: null,
  },
  validation: {
    type: Object,
    default: () => ({}),
  },
}

export function useInput(props: InputProps, emit: InputEmits) {
  const uid = (getCurrentInstance()?.proxy as any)?._uid

  const validationProviderRef = ref<InstanceType<
    typeof ValidationProvider
  > | null>(null)
  const isFocused = ref(false)

  const id = computed(() => `${props.name || 'input'}-${uid}`)
  const validationMode = computed(() => props.validation?.mode || 'eager')

  function onFocus() {
    isFocused.value = true

    if (!props.disabled) {
      emit('focus')
    }
  }

  function onBlur() {
    isFocused.value = false

    validateOnBlur()
    emit('blur')
  }

  function onInput(value: InputValue) {
    validateOnInput(value)
    emit('input', value)
  }

  async function validateOnBlur(value?: InputValue) {
    if (validationMode.value === 'lazy' || validationMode.value === 'eager') {
      return validationProviderRef.value?.validate(value ?? props.value)
    }
  }

  async function validateOnInput(value: InputValue) {
    if (
      validationMode.value === 'aggressive' ||
      (validationMode.value === 'eager' &&
        validationProviderRef.value?.flags.validated)
    ) {
      return validationProviderRef.value?.validate(value)
    } else if (
      validationMode.value === 'eager' &&
      !validationProviderRef.value?.flags.validated
    ) {
      validationProviderRef.value?.syncValue(value)
      return validationProviderRef.value?.validateSilent()
    }
  }

  async function validate(value: InputValue) {
    return validationProviderRef.value?.validate(value)
  }

  async function validateSilent() {
    return validationProviderRef.value?.validateSilent()
  }

  function syncValue(value: InputValue | undefined) {
    validationProviderRef.value?.syncValue(value ?? '')
  }

  async function reset() {
    return validationProviderRef.value?.reset()
  }

  onMounted(() => {
    syncValue(props.value)
    validateSilent()
  })

  watch(
    () => props.value,
    (value) => {
      syncValue(value)
    }
  )

  return {
    validationProviderRef,
    isFocused,
    id,
    validationMode,
    validateOnBlur,
    validateOnInput,
    validate,
    validateSilent,
    reset,
    onFocus,
    onBlur,
    onInput,
    syncValue,
  }
}
